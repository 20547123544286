import { Container, Row, Col } from 'react-bootstrap';

export default function Footer() {
  return(
    <footer className="py-3">
      <Container fluid>
        <Row>
          <Col md={6} className="text-center text-md-start font-small mb-3 mb-md-0 ps-3">
            <span>2024©</span>
            <span className="text-heading">Yatırım Nedir¿</span>
          </Col>
          <Col md={6}>
            <ul className="list-inline m-0 text-center text-md-end font-small">
              <li className="list-inline-item me-4"><a href="/cerez-politikasi" className="text-color text-decoration-none">Çerez Pollitikası</a></li>
              <li className="list-inline-item me-2"><a href="/iletisim" className="text-color text-decoration-none">İşbirliği/İletişim</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}