import Link from 'next/link';
import Image from 'next/image';
import { Container, Row, Col } from 'react-bootstrap';

export default function MobileApp() {
  return (
    <Row className='mb-4'>
      <Col lg={12}>
        <div className='card-shadow'>
          <div className='p-4 text-center'>
            <strong className='d-block text-heading mb-2'>Mobil uygulamamız yayında 🎉</strong>
            <Link prefetch={false} href='https://apps.apple.com/app/id6587574284' target='_blank'>
              <Image
                src='https://api.yatirimnedir.com/images/ios-button-sm.svg'
                alt='Logo'
                quality={100}
                width={150}
                height={45}
                title="Yatırım Nedir: Temettü Hisseleri - Yatırım Fonları - BES Fonları Analiz"
              />
            </Link>
            <Link prefetch={false} href='https://play.google.com/store/apps/details?id=com.yatirimnedir' target='_blank'>
              <Image
                src='https://api.yatirimnedir.com/images/android-button-sm.svg'
                alt='Logo'
                quality={100}
                width={150}
                height={45}
                title="Yatırım Nedir: Temettü Hisseleri - Yatırım Fonları - BES Fonları Analiz"
              />
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
}