import Link from 'next/link';
import * as Icon from 'react-feather';

const BasicFundCard = ({ title, trend, button, buttonText = '', buttonURL = '', funds, hisse }) => {
  return (
    <div className='card-shadow'>
      <div className='p-4 border-bottom'>
        <h2 className='h6 m-0' dangerouslySetInnerHTML={{ __html: title }}></h2>
      </div>
      <div className='p-4 pt-0'>
        <table className={`table table-border-dashed font-small ${!button ? 'mb-0' : ''}`}>
          <tbody>
            {funds.map((fund, index) => (
              <tr key={index}>
                <td className='ps-0 w-75'>
                  <Link prefetch={false} href={fund.url} className="text-decoration-none text-heading d-flex align-items-center">
                    <span className={hisse ? 'item-icon-2 me-3' : 'item-icon me-3'}>{fund.name}</span>
                    <span>{fund.value}</span>
                  </Link>
                </td>
                <td className='text-end pe-0'>
                  {trend === 'up' ? (
                    <>
                      <Icon.TrendingUp className='text-success me-2'/>
                      <strong className='text-success'>{fund.percentage}</strong>
                    </>
                  ) : (
                    <>
                      <Icon.TrendingDown className='text-danger me-2'/>
                      <strong className='text-danger'>{fund.percentage}</strong>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {
          button && (
            trend === 'up' ? (
              <Link prefetch={false} href={buttonURL} className="btn btn-success d-block">{buttonText}</Link>
            ) : (
              <Link prefetch={false} href={buttonURL} className="btn btn-danger d-block">{buttonText}</Link>
            )
          )
        }
      </div>
    </div>
  );
}

export default BasicFundCard;