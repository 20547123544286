import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import * as Icon from 'react-feather';
import { Scrollbars } from 'react-custom-scrollbars';

export default function Search() {
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [temettuHisseSayisi, setTemettuHisseSayisi] = useState(0);
  const [temettuListesi, setTemettuListesi] = useState([]);
  const [fonSayisi, setFonSayisi] = useState(0);
  const [fonListesi, setFonListesi] = useState([]);
  const [besSayisi, setBesSayisi] = useState(0);
  const [besListesi, setBesListesi] = useState([]);

  useEffect(() => {
    const fetchSearch = async () => {
      try {
        const response = await fetch(`https://api.yatirimnedir.com/search.php?q=${searchValue}`);
        const jsonData = await response.json();
        setTemettuHisseSayisi(jsonData.hisseSayisi);
        setTemettuListesi(jsonData.hisse);
        setFonSayisi(jsonData.fonSayisi);
        setFonListesi(jsonData.fon);
        setBesSayisi(jsonData.besFonSayisi);
        setBesListesi(jsonData.besFon);
      } catch (error) {
        console.error('API isteğinde hata:', error);
      }
    };

    if(searchValue){
      const timeout = setTimeout(() => { 
        setSearch(true);
        fetchSearch();
      }, 200);
      return () => {
        clearTimeout(timeout);
      }
    }else{
      setSearch(false);
    }
  }, [searchValue]);

  return(
    <div className='search-container'>
      <div className='search-input'>
        <input 
          type='text' 
          placeholder='Hisse veya fon kodu ara...'
          onChange={e=> setSearchValue(e.target.value)}
        />
        <Icon.Search />
      </div>
      <div className={`search-results ${(!search ? 'results-hide' : '')}`}>
        <Scrollbars style={{ height: '350px'}}>
          <strong className='search-title'>
            TEMETTÜ HİSSELERİ
            {temettuHisseSayisi != 0 &&
              <small>{temettuHisseSayisi} sonuç bulundu</small>
            }
          </strong>
          {temettuHisseSayisi != 0 &&
            <ul className='mb-3'>
              {temettuListesi.map((temettu, index) => (
                <li key={index}>
                  <Link prefetch={false} href={`/hisse-analiz/${temettu.seflink}`} target="_blank">
                    <span className='item-icon-2 me-3'>{temettu.kod}</span>
                    <span>{temettu.hisse_adi}</span>
                  </Link>
                </li>
              ))}
            </ul>
          }
          {temettuHisseSayisi == 0 &&
            <p>Sonuç bulunamadı.</p>
          }
          <strong className='search-title'>
            YATIRIM FONLARI
            {fonSayisi != 0 &&
              <small>{fonSayisi} sonuç bulundu</small>
            }
          </strong>
          {fonSayisi != 0 &&
            <ul className='mb-3'>
              {fonListesi.map((fon, index) => (
                <li key={index}>
                  <Link prefetch={false} href={`/fon-analiz/${fon.seflink}`} target="_blank" className="text-decoration-none text-heading d-flex align-items-center">
                    <span className='item-icon me-3'>{fon.kod}</span>
                    <span>{fon.fon_adi}</span>
                  </Link>
                </li>
              ))}
            </ul>
          }
          {fonSayisi == 0 &&
            <p>Sonuç bulunamadı.</p>
          }
          <strong className='search-title'>
            BES FONLARI
            {besSayisi != 0 &&
              <small>{besSayisi} sonuç bulundu</small>
            }
          </strong>
          {besSayisi != 0 &&
            <ul className='mb-3'>
              {besListesi.map((bes, index) => (
                <li key={index}>
                  <Link prefetch={false} href={`/bes-fon-analiz/${bes.seflink}`} target="_blank" className="text-decoration-none text-heading d-flex align-items-center">
                    <span className='item-icon me-3'>{bes.kod}</span>
                    <span>{bes.fon_adi}</span>
                  </Link>
                </li>
              ))}
            </ul>
          }
          {besSayisi == 0 &&
            <p>Sonuç bulunamadı.</p>
          }
        </Scrollbars>
      </div>
    </div>
  );
}